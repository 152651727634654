import Head from 'next/head';
import Link from 'next/link';

export default function Home() {
  return (
    <div>
      <Head>
        <title>École du Sacré Coeur - Boussay</title>
        <meta
          name="description"
          content="Bienvenue sur l'école privé du Sacré Coeur de Boussay. Ecole Maternelle et Primaire située entre Montaigu Nantes et Cholet. Retrouvez en détail toute l'actualité de l'école et des élèves."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="wallpaper">
        <div className="h-screen pt-52 md:pt-56">
          <div className='flex flex-col items-center w-11/12 pt-20 pb-20 ml-auto mr-auto text-center text-white bg-black rounded md:w-6/12 opacity-80'>
            <h1 className="w-11/12 text-lg opacity-100 md:text-2xl">
              Bienvenue à l&apos;École du Sacré Coeur <br /> de Boussay
            </h1>
            <Link href="/accueil">
              <a href="">
                <h2 className='w-48 h-10 p-1 mt-10 text-lg text-white rounded animate-bounce hover:bg-white hover:text-blue-school md:text-2xl bg-blue-school'>Entrer</h2>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
